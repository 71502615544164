/**
 * Has issues importing from '@concord/types' package when building the package through rollup. The bundler
 * is looking at the package and expects it to be compiled js / type definitions and not typescript.
 * TODO: look into solution for above
 */

export type SerializedError = {
  errorType?: string | null;
  message?: string | null;
};

export abstract class CustomError extends Error {
  statusCode = 200;

  constructor(message: string) {
    super(message);

    this.name = this.constructor.name;

    // restore prototype chain
    const actualProto = new.target.prototype;

    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(this, actualProto);
    } else {
      (this as any).__proto__ = actualProto;
    }
  }

  abstract serialize(): SerializedError;
}

export class GenericError extends CustomError {
  serialize(): SerializedError {
    return {
      errorType: this.name,
      message: this.message,
    };
  }
}

/**
 * Metadata specific to session
 */
export interface AnalyticsMetadata {
  os?: string;
  osVersion?: string;
  osVersionName?: string;
  device?: string;
  browser?: string;
  browserVersion?: string;
  platform?: string;
  ip?: string;
  locale?: string;
  timezone?: string;
}

export type CustomAttributes = Record<
  string,
  string | number | boolean | Date | null
>;

export type Response<T> = {
  error?: SerializedError;
} & T;

/**
 * The events bellow should follow the following conventions:
 * - Actions should be expressed as past tense
 * - Enum keys should be in CAPITAL_CASE
 * - Enum values should be formatted as `PascalCaseObject PascalCaseAction`
 */
export enum ReservedEvent {
  ACCOUNT_CREATED = 'Account Created',
  ACCOUNT_DELETED = 'Account Deleted',
  USER_PASSWORD_RESET = 'UserPassword Reset',
  USER_CREATED = 'User Created',
  USER_DELETED = 'User Deleted',
  USER_IDENTIFIED = 'User Identified',
  USER_INVITE_SENT = 'UserInvite Sent',
  TRIAL_STARTED = 'Trial Started',
  TRIAL_ENDED = 'Trial Ended',
  SESSION_ENDED = 'Session Ended',
  PAGE_VIEWED = 'Page Viewed',
}

export type EventTypes = ReservedEvent | string;

export type AccountCreatedEventData = {
  accountId?: string;
  accountName?: string;
  domain?: string;
  planName?: string;
};

export type AccountDeletedEventData = {
  accountId?: string;
  accountName?: string;
};

export type UserPasswordResetEventData = {
  userId?: string;
  userEmail?: string;
};

export type UserCreatedEventData = {
  accountId?: string;
  accountName?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  username?: string;
  title?: string;
};

export type UserDeletedEventData = {
  userId?: string;
};

export type UserIdentifiedEventData = {
  email?: string;
  firstName?: string;
  lastName?: string;
  joinDate?: Date;
  accountId?: string;
};

export type UserInviteSentEventData = {
  inviteeEmail?: string;
  inviteeFirstName?: string;
  inviteeLastName?: string;
  inviteeRole?: string;
};

export type TrialStartedEventData = {
  trialStartDate?: Date;
  trialEndDate?: Date;
  trialPlanName?: string;
};

export type TrialEndedEventData = {
  trialStartDate?: Date;
  trialEndDate?: Date;
  trialPlanName?: string;
};

export type PageViewedEventData = {
  pageTitle?: string;
  pageUrl?: string;
};

export type EventData =
  | AccountCreatedEventData
  | AccountDeletedEventData
  | UserPasswordResetEventData
  | UserCreatedEventData
  | UserDeletedEventData
  | UserIdentifiedEventData
  | UserInviteSentEventData
  | TrialStartedEventData
  | TrialEndedEventData
  | PageViewedEventData
  | Record<string, any>;

export interface EventBase {
  type: EventTypes;
  timestamp?: Date;
  data?: EventData;
}

export interface Event extends EventBase {
  userId: string;
  timestamp?: Date;
  referrer?: string;
  hostname?: string;
  pathname?: string;
}

export class ApiKeyNotFoundError extends GenericError {
  constructor() {
    super(`API key not found`);
  }
}

export class MissingParamError extends GenericError {
  constructor(paramName: string) {
    super(`Param ${paramName} not found`);
  }
}
