import { Event, MissingParamError, Response } from '../types';
import { RequiredParams, post } from '../utils/fetch';

export interface SendProps extends RequiredParams {
  events: Event[];
}

export interface SendResponse {
  acknowledged: boolean;
}

export const send = async (params: SendProps) => {
  if (!params.events) {
    throw new MissingParamError('events');
  }

  const response = await post<SendProps, Response<SendResponse>>(
    '/events',
    params,
  );

  return response || { acknowledged: false };
};
