/* eslint-disable turbo/no-undeclared-env-vars */

/* spell-checker: ignore bowser */
import Bowser from 'bowser';

import { send } from './api/send';
import { EventNameTooLong, KeyTooLong } from './errors';
import {
  AnalyticsMetadata,
  CustomAttributes,
  Event,
  EventBase,
  EventData,
  EventTypes,
  PageViewedEventData,
  ReservedEvent,
} from './types';
import storage from './utils/storage';

const KEY_MAX_LENGTH = 48;

export enum StorageKeys {
  EVENT_QUEUE = 'eventQueue',
}

export interface SyrupAnalyticsConfig {
  /**
   * Enables verbose logging
   */
  debug?: boolean;
  /**
   * API key assigned to tenant. Required to access.
   */
  apiKey: string;
  /**
   * The unique identifier for the user. This should be static for the lifetime of the user.
   */
  userId: string;
  /**
   * Email of user to track events for. Required as unique identifier.
   */
  email: string;
  /**
   * Optional first name of the user
   */
  firstName?: string;
  /**
   * Optional last name of the user
   */
  lastName?: string;
  /**
   * Optional join date of user
   */
  joinDate?: Date;
  /**
   * Optional account id of user
   */
  accountId?: string;
  /**
   * Any custom attributes associated with the user. Use `null` to unset an attribute previously set.
   */
  customAttributes?: CustomAttributes;
}

const defaultConfig: Partial<SyrupAnalyticsConfig> = {
  debug: false,
};

export class SyrupAnalyticsClient {
  public isInitialized: boolean;
  private readonly config: SyrupAnalyticsConfig;
  private metadata?: AnalyticsMetadata;
  private eventQueue: Event[] = [];
  private initPromise: Promise<void> | null = null;

  constructor(config: SyrupAnalyticsConfig) {
    this.config = { ...defaultConfig, ...config };
    this.log('Initializing Syrup');

    // Load queued events from storage
    this.eventQueue = storage.getItem(StorageKeys.EVENT_QUEUE) ?? [];
    storage.setItem(StorageKeys.EVENT_QUEUE, this.eventQueue);

    // Calls in background to not block, but delays setting isInitialized
    this.initPromise = this.identify();
  }

  /**
   * Sends identify event to API
   */
  private async identify() {
    this.log('Identifying User');

    // Capture identified event
    const metadata = this.setMetadata();
    await this.send(ReservedEvent.USER_IDENTIFIED, {
      ...metadata,
      email: this.config.email,
      firstName: this.config.firstName,
      lastName: this.config.lastName,
      joinDate: this.config.joinDate,
      accountId: this.config.accountId,
      customAttributes: this.config.customAttributes,
    });

    // Send queued data
    this._sendQueue();

    this.isInitialized = true;
  }

  /**
   * Set browser metadata
   */
  private setMetadata() {
    if (typeof window !== 'undefined') {
      const bowser = Bowser.parse(window.navigator.userAgent);

      this.metadata = {};
      this.metadata.browser = bowser.browser.name;
      this.metadata.browserVersion = bowser.browser.version;
      this.metadata.os = bowser.os.name;
      this.metadata.osVersion = bowser.os.version;
      this.metadata.osVersionName = bowser.os.versionName;
      this.metadata.platform = bowser.platform.type;
      this.metadata.platform = bowser.platform.type;
      this.metadata.locale = window.navigator.language;
      this.metadata.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }

    return this.metadata;
  }

  /**
   * Get browser metadata
   */
  public getMetadata() {
    return this.metadata;
  }

  /**
   * Get original config passed
   */
  public getConfig() {
    return this.config;
  }

  /**
   * Send multiple events to the usage API
   */
  public async sendBulk(...events: EventBase[]) {
    return this._send(...events);
  }

  /**
   * Send single event to the usage API
   *
   * @param eventType The type of event to send
   * @param [eventData] Optional data to send with the event
   * @param [timestamp] Optional timestamp to use for the event. Defaults to current time
   */
  public async send(
    eventType: EventTypes,
    eventData?: EventData,
    timestamp?: Date,
  ) {
    return this._send({
      type: eventType,
      data: eventData,
      timestamp,
    });
  }

  /**
   * Send event data to the usage API
   */
  private async _send(...events: EventBase[]) {
    const { hostname, pathname } = window.location;

    const eventsFinal = (Array.isArray(events) ? events : [events]).map((e) => {
      if (e.type.length > KEY_MAX_LENGTH) {
        throw new EventNameTooLong(
          `Event type "${e.type}" is ${e.type.length} characters. Event types have a max length of ${KEY_MAX_LENGTH} characters `,
        );
      }

      if (e.data) {
        for (const key of Object.keys(e.data)) {
          if (key.length > KEY_MAX_LENGTH) {
            throw new KeyTooLong(
              `Key ${key} is ${key.length} characters. Keys have a max length of ${KEY_MAX_LENGTH} characters.`,
            );
          }
        }
      }

      return {
        type: e.type,
        data: e.data,
        timestamp: e.timestamp || new Date(),
        userId: this.config.userId,
        referrer: document.referrer,
        hostname,
        pathname,
      } as Event;
    });

    if (!this.isInitialized) {
      this.enqueue(...eventsFinal);
      return;
    }

    await send({
      apiKey: this.config.apiKey,
      events: eventsFinal,
    });
  }

  /**
   * Send event data to the usage API from the queue
   */
  private async _sendQueue() {
    this.log('Sending queued events');
    const queue = this.getEventQueue();

    // Reset stored event queue data
    this.eventQueue = [];
    storage.setItem(StorageKeys.EVENT_QUEUE, this.eventQueue);

    if (!queue.length) {
      this.log('No queued events');
      return;
    }

    return send({
      apiKey: this.config.apiKey,
      events: queue,
    });
  }

  /**
   * Send a `Page Viewed` event to the usage API. This will automatically read the page title and URL
   * from the browser window. If using Server-Side Rendering, you should provide the data manually.
   *
   * @param [data] Optional data to override any of the default values
   */
  public async sendPageView(data?: Partial<PageViewedEventData>) {
    return this.send(ReservedEvent.PAGE_VIEWED, {
      pageTitle:
        data?.pageTitle ??
        (typeof window !== 'undefined' ? window.document.title : undefined),
      pageUrl:
        data?.pageUrl ??
        (typeof window !== 'undefined'
          ? window.location.href.replace(/^https?:\/\//i, '')
          : undefined),
    });
  }

  /**
   * Set a custom attribute for the user
   *
   * @param name The name of the attribute
   * @param value The value for the attribute
   */
  public async setCustomAttribute(
    name: string,
    value: string | number | boolean | Date | null,
  ) {
    // Wait for initialization to complete
    await this.initPromise;

    if (!this.isInitialized) {
      this.log('SDK not initialized');
      return;
    }

    this.config.customAttributes = {
      ...this.config.customAttributes,
      [name]: value,
    };

    await this.identify();
  }

  /**
   * Pushes event to queue to be handled when possible
   */
  private enqueue(...event: Event[]) {
    this.eventQueue.push(...event);
    storage.setItem(StorageKeys.EVENT_QUEUE, this.eventQueue);
  }

  /**
   * Get entire event queue (if any)
   */
  public getEventQueue() {
    return this.eventQueue ?? [];
  }

  /**
   * Console debug wrapped with debug flag
   * @param message
   * @param optionalParams
   */
  public log(message?: any, ...optionalParams: any[]) {
    if (!this.config.debug) {
      return;
    }

    console.debug(`[Syrup.io] ${message}`, ...optionalParams);
  }
}

let _instance: SyrupAnalyticsClient | undefined;

export const initialize = (config: SyrupAnalyticsConfig) => {
  // Return global instance if one exists
  if (_instance) {
    const { userId, apiKey } = _instance.getConfig();
    if (config.userId === userId && config.apiKey === apiKey) {
      return _instance;
    }
  }

  _instance = new SyrupAnalyticsClient(config);
  return _instance;
};

export const destroy = () => {
  _instance = undefined;
};

export * from './types';
