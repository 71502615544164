import { hasCookiesSupport } from './cookie';
import {
  GLOBAL,
  StorageType,
  clear as clearGlobalStorage,
  get as getFromGlobalStorage,
  remove as removeFromGlobalStorage,
  set as setToGlobalStorage,
} from './global';
import {
  clear as clearLocalStorage,
  get as getFromLocalStorage,
  hasLocalStorage,
  remove as removeFromLocalStorage,
  set as setToLocalStorage,
} from './local';
import {
  clear as clearSessionStorage,
  get as getFromSessionStorage,
  hasSessionStorage,
  remove as removeFromSessionStorage,
  set as setToSessionStorage,
} from './session';

/**
 * Get storage item from localStorage, cookie, or window
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export function getItem<T = any>(
  key: string,
  type?: StorageType,
): T | undefined {
  if (!key) return;
  const storageType = type ?? StorageType.ANY;

  /* 1. Try localStorage */
  const localValue = useLocal(storageType)
    ? getFromLocalStorage<T>(key)
    : undefined;
  if (localValue) {
    return localValue;
  }

  /* 2. Fallback to cookie */
  // const cookieVal = useCookie(storageType) ? parse(getCookie(key)) : undefined;
  // if (cookieVal) {
  //   return cookieVal;
  // }

  /* 3. Fallback to sessionStorage */
  const sessionVal = useSession(storageType)
    ? getFromSessionStorage<T>(key)
    : undefined;
  if (sessionVal) {
    return sessionVal;
  }

  /* 4. Fallback to window/global. */
  if (useGlobal(storageType)) {
    return getFromGlobalStorage<T>(key);
  }

  return;
}

/**
 * Store values in localStorage, cookie, or window
 */
export function setItem<T>(key: string, value: T, type?: StorageType) {
  if (!key || value === undefined) {
    return;
  }
  const storageType = type ?? StorageType.ANY;

  /* 1. Try localStorage */
  if (useLocal(storageType)) {
    return setToLocalStorage(key, value);
  }

  /* 2. Fallback to cookie */
  // if (useCookie(type)) {
  //   // console.log('SET as cookie', saveValue)
  //   data[COOKIE] = format(COOKIE, value, parse(getCookie(key)));
  //   // Set Cookie
  //   setCookie(key, saveValue);
  //   if (setFirst) {
  //     return data[COOKIE];
  //   }
  // }

  /* 3. Try sessionStorage */
  if (useSession(storageType)) {
    return setToSessionStorage(key, value);
  }

  /* 4. Fallback to window/global */
  if (useGlobal(storageType)) {
    return setToGlobalStorage(key, value);
  }

  return;
}

/**
 * Remove values from localStorage, cookie, or window
 */
export function removeItem(key: string, type?: StorageType) {
  if (!key) {
    return;
  }
  const storageType = type ?? StorageType.ANY;

  /* 1. Try localStorage */
  const localValue = useLocal(storageType)
    ? removeFromLocalStorage(key)
    : undefined;
  if (localValue) {
    return localValue;
  }

  /* 2. Fallback to cookie */
  // if (!isUndefined(values.cookie) && useCookie(type)) {
  //   removeCookie(key);
  //   data[COOKIE] = values.cookie;
  // }

  /* 3. Try sessionStorage */
  const sessionValue = useLocal(storageType)
    ? removeFromSessionStorage(key)
    : undefined;
  if (sessionValue) {
    return sessionValue;
  }

  /* 4. Fallback to window/global */
  return removeFromGlobalStorage(key);
}

/**
 * Resets all storage
 */
export function reset() {
  clearGlobalStorage();
  clearLocalStorage();
  clearSessionStorage();
}

function useGlobal(storageType: StorageType) {
  return useType(storageType, StorageType.WINDOW);
}

function useLocal(storageType: StorageType) {
  return hasLocalStorage() && useType(storageType, StorageType.LOCAL);
}

function useCookie(storageType: StorageType) {
  return hasCookiesSupport() && useType(storageType, StorageType.COOKIE);
}

function useSession(storageType: StorageType) {
  return hasSessionStorage() && useType(storageType, StorageType.SESSION);
}

function useType(storageType: StorageType, type: StorageType) {
  return storageType === StorageType.ANY || storageType === type;
}

export {
  //   ALL,
  //   ANY,
  GLOBAL,
  //   COOKIE,
  //   LOCAL_STORAGE,
  //   SESSION_STORAGE,
  //   getCookie,
  //   setCookie,
  //   removeCookie,
  //   globalContext,
  useSession,
  useCookie,
  useType,
  useLocal,
};

export default {
  setItem,
  getItem,
  removeItem,
  reset,
};
