/* eslint-disable @typescript-eslint/naming-convention */
import { ApiKeyNotFoundError } from '../types';

const config = {
  // eslint-disable-next-line turbo/no-undeclared-env-vars
  API_URL: process.env.USAGE_API_BASE_URL || '',
};

export interface RequiredParams {
  apiKey: string;
}

export const apiFetch = async <
  TParams extends RequiredParams,
  TResponse = unknown,
>(
  url: string,
  method = 'GET',
  params?: TParams,
) => {
  if (!params || !params.apiKey) throw new ApiKeyNotFoundError();

  const { apiKey, ...data } = params;

  const options: RequestInit = {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-API-Key': apiKey,
    } as Record<string, string>,
  };

  let queryParams = '';

  // add post data
  if (data) {
    if (method === 'GET') {
      queryParams = '?' + new URLSearchParams(data as any);
    } else {
      options.body = JSON.stringify(data);
    }
  }

  const response = await fetch(url + queryParams, options);

  if (!response.ok) {
    throw Error(response.statusText);
  }

  return (await response.json()) as TResponse;
};

// ajax get method
export const get = <TParams extends RequiredParams, TResponse = unknown>(
  path: string,
  data?: TParams,
) => apiFetch<TParams, TResponse>(config.API_URL + path, 'GET', data);

// ajax post method
export const post = <TParams extends RequiredParams, TResponse = unknown>(
  path: string,
  data: TParams,
) => apiFetch<TParams, TResponse>(config.API_URL + path, 'POST', data);

// ajax delete method
export const remove = <TParams extends RequiredParams, TResponse = unknown>(
  path: string,
  data: TParams,
) => apiFetch<TParams, TResponse>(config.API_URL + path, 'DELETE', data);

// ajax patch method
export const patch = <TParams extends RequiredParams, TResponse = unknown>(
  path: string,
  data: TParams,
) => apiFetch<TParams, TResponse>(config.API_URL + path, 'PATCH', data);
