import { GLOBAL_APP, StorageType, hasSupport } from './global';
import { parse } from './helpers';

export const hasLocalStorage = hasSupport.bind(null, StorageType.LOCAL);

// eslint-disable-next-line @typescript-eslint/ban-types
export const get = <T>(key: string) => {
  const appData = parse(localStorage.getItem(GLOBAL_APP));

  return appData[key] ? (appData[key] as T) : undefined;
};

export const set = <T>(key: string, value: T) => {
  const appData = parse(localStorage.getItem(GLOBAL_APP));

  appData[key] = value;

  localStorage.setItem(GLOBAL_APP, JSON.stringify(appData));
  return appData;
};

export const remove = (key: string) => {
  const appData = parse(localStorage.getItem(GLOBAL_APP));

  if (appData[key]) {
    delete appData[key];
    localStorage.setItem(key, JSON.stringify(appData));
  }

  return appData;
};

export const clear = () => {
  localStorage.setItem(GLOBAL_APP, JSON.stringify({}));
};
