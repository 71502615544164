/* eslint-disable @typescript-eslint/no-namespace */
export const GLOBAL = 'global';
export const GLOBAL_APP = '__syrup__';

export enum StorageType {
  'ANY',
  'LOCAL',
  'COOKIE',
  'SESSION',
  'WINDOW',
}

export const storageTypeMap = new Map<number, string>([
  [StorageType.LOCAL, 'localStorage'],
  [StorageType.COOKIE, 'cookie'],
  [StorageType.SESSION, 'sessionStorage'],
]);

let cache: Record<string, boolean> = {} as Record<string, boolean>;

// Verify support
export function hasSupport(type: StorageType.LOCAL | StorageType.SESSION) {
  const storageKey = storageTypeMap.get(type);

  if (storageKey === undefined) return;

  if (typeof cache[storageKey] !== 'undefined') {
    return cache[storageKey];
  }

  try {
    const storage = (window as any)[storageKey];

    storage.setItem(undefined, undefined);
    storage.removeItem(undefined);
  } catch (err) {
    return (cache[storageKey] = false);
  }
  return (cache[storageKey] = true);
}

export const globalContext =
  (typeof self === 'object' && self.self === self && self) ||
  (typeof global === 'object' && global[GLOBAL] === global && global) ||
  undefined;

/* initialize global object */
if (globalContext && !(globalContext as any)[GLOBAL_APP]) {
  (globalContext as any)[GLOBAL_APP] = {};
}
/**
 * Get value from global context
 */
export function get<T>(key: string) {
  if (!globalContext || !(globalContext as any)[GLOBAL_APP]) {
    return undefined;
  }
  return (globalContext as any)[GLOBAL_APP][key] as T;
}

/**
 * Set value to global context
 */
export function set(key: string, value: any) {
  if (!globalContext || !(globalContext as any)[GLOBAL_APP]) {
    return undefined;
  }

  (globalContext as any)[GLOBAL_APP][key] = value;
  return (globalContext as any)[GLOBAL_APP];
}

/**
 * Remove value to global context
 */
export function remove(key: string) {
  if (!globalContext || !(globalContext as any)[GLOBAL_APP]) {
    return undefined;
  }

  delete (globalContext as any)[GLOBAL_APP][key];
  return (globalContext as any)[GLOBAL_APP];
}

/**
 * Clear all values in global context
 */
export function clear() {
  if (!globalContext) {
    return undefined;
  }

  (globalContext as any)[GLOBAL_APP] = {};
  return (globalContext as any)[GLOBAL_APP];
}

export function resetCache() {
  cache = {};
}
