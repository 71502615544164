export const parse = (val: string | null) => {
  try {
    return JSON.parse(val || '{}');
  } catch (e) {
    return {};
  }
};

/**
 * Helper function to check equality of two values. Performs
 * deep equality when values are objects.
 */
export const isEqual = (val1: any, val2: any) => {
  if (val1 === val2) {
    // Strong equality check
    return true;
  }

  if (
    typeof val1 !== 'object' ||
    val1 === null ||
    typeof val2 !== 'object' ||
    val2 === null
  ) {
    return false;
  }

  const keys1 = Object.keys(val1);
  const keys2 = Object.keys(val2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key) || !isEqual(val1[key], val2[key])) {
      return false;
    }
  }

  return true;
};
