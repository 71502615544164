import { SyrupAnalyticsConfig, initialize } from './analytics';

const SyrupAnalytics = (config: SyrupAnalyticsConfig) => {
  const instance = initialize(config);

  if (instance) {
    if (typeof window !== 'undefined') {
      (window as any)['syrup'] = instance;
    }
  }
  return instance;
};

export default SyrupAnalytics;
